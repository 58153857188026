.contact{
  margin: 40px 0;
  padding: 40px 72px;
  @media (max-width: 575.98px) {
    padding: 40px;
  }
}

.contact-form {
  // background: linear-gradient(90deg, #fff 3%, #E6F5EA 0);
  background-color: #E6F5EA;

 

  .section-right {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 80px;
    padding-bottom: 20px;
  }

  .section-left {
    display: flex;
    flex: 1 1 0;
  }

  .section-left img {
    width: 100%;
  }

  input,
  textarea {
    padding: 12px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    box-sizing: border-box;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 60px;
    margin: 0;
    color: #238e43;
    text-align: center;
    padding: 10px 0;
  }

  .subtitle {
    font-weight: 300;
    font-size: 24px;
    line-height: 26px;
    color: #353d54;
    margin: 0 0 40px 0;
    text-align: center;
  }

  .email, .query {
    margin-bottom: 16px;
  }

  .button-container {
    display: flex;
    align-self: flex-end;
  }
  @media (max-width: 991.98px) {
    .section-right{
      width: 70%;
    }
  }
  @media (max-width: 767.98px) {
    .section-right{
    
      padding: 0px ;
      padding-bottom: 20px;
    }
   
  }
  @media (max-width: 575.98px) {
    .section-right{
      padding: 0px 20px;
      padding-bottom: 20px;
    }
   
  }

}
