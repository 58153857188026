.link {
  background: #3EAE5B;
  border-width: 0;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  color: #FFFFFF;
  padding: 16px;
  gap: 8px;
  width: fit-content;
  text-decoration: none;
}