.header {
  position: relative;
  &.transparent {
    background-color: transparent;
    z-index: 1;
    .nav-link .menu-item {
      color: #fff;

      svg path {
        stroke: #fff;
      }
    }
  }
}

.header,
.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  // margin-bottom: 40px;
}

.header {
  .header-wrapper {
    width: 100%;
    padding: 8px;
    margin: 0 auto;
    border-bottom: 1px solid #dadce0;
    @media (max-width: 575.98px) {
      padding: 12px;
    }
  }

  .header-left {
    display: flex;
    align-items: center;

    .container {
      display: inherit;
      text-decoration: none;
    }
  }

  .logo-icon {
    max-width: 100%;
    height: auto;
  }

 /* .logo-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 54px;
    color: #35af58;
    margin-left: 12px;
  }*/

  .header-right {
    margin-left: auto;
  }

  .nav-link {
    padding: 4px;
    margin: 8px;
    display: inline;

    &.dropdown {
      cursor: pointer;

      .dropdown-list {
        display: none;
        &.open {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          padding: 0;
          position: absolute;
          right: 20px;
          text-align: left;
          box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
          border-radius: 8px;
          background-color: #fff;
          z-index: 2;
        }

        .list-item {
          display: inline;

          .submenu-item {
            display: inline-block;
            color: #19233D;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            padding: 16px;
            text-decoration: none;
            width: 100%;
            box-sizing: border-box;
            &.active {
              font-weight: 600;
              background-color: #F6F6F7;
            }
          }
        }
      }
    }
  }

  .nav-link .menu-item {
    color: #a3a7b1;
    font-weight: 400;
    font-size: 26px;
    line-height: 42px;
    text-decoration: none;

    svg path {
      stroke: #a3a7b1;
    }
  }

  .nav-link .menu-item.active {
    color: #a3a7b1;
    font-weight: 400;

    svg path {
      stroke: #238e43;
    }
  }

  .mobile-menu {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    //do Smth
    .mobile-menu {
      display: block;
    }
    .link-list {
      display: none;
      background-color: white;
      border: solid 0.5px #dadada;
      box-shadow: 5px 5px #fafafa;
      &.active {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: absolute;
        right: 20px;
        text-align: right;
        padding: 8px;
      }
    }
  }

  .link-list {
    margin: 0;
  }
}
