.section-data {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: center;
  padding: 10px 40px;

  .tagLiner {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.01em;
    color: #238e43;
    flex: none;
    margin: 20px 0;
  }

  .header-text {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;

    letter-spacing: 0.05em;
    text-transform: capitalize;
    margin-bottom: 20px;
    direction: ltr;
    @media (max-width: 575.98px) {
      font-size: 25px;
    }
  }

  .subheader {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-left: 4px solid #9dcdcf;
    padding-left: 12px;
    color: #8A8884;
    width: 70%;
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
    direction: ltr;
    color: #8A8884;
    width: 70%;
  }


  @media screen and (max-width: 1024px) {
    .header-text {
    
      line-height: 45px;

      &.monster {
        font-size: 40px;
        line-height: 60px;
      }
    }
  }
  @media only screen and (min-width: 477px) {
    .subheader{
      width: 86%;
    }
    .description{
      width: 100%;
    }
  }
  @media only screen and (max-width: 477px) {
    .subheader{
      width: 86%;
    }
    .description{
      width: 100%;
    }
  }
  &.monster {
    flex-basis: 30%;
    .header-text {
      font-size: 60px;
      line-height: 80px;
    }
    .description {
      font-size: 28px;
      line-height: 40px;
    }
  }
}

.rtl {
  .section-data {
    .header-text {
      text-align: right;
    }

    .description {
      text-align: right;
    }
  }
}

