.modal-bg {
  background-color: #7f7f7f99;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 50;
  inset: 0;
  position: fixed;

  &.active {
    display: flex;
  }

  .modal-wrapper {
    height: auto;
    padding: 1rem;
    width: 100%;
    max-width: 490px;
    position: relative;

    .modal-main {
      --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
      --tw-shadow-colored: 0 4px 6px -1pxvar(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      background-color: rgb(255 255 255);
      position: relative;
    }
  }
}