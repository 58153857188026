.section-with-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // min-height: 640px;
  width: 100%;

  &.rtl {
    direction: rtl;
  }

  .section-right {
    display: flex;
    flex: 1 1 0;
    justify-content: end;
    padding: 20px 80px;
  }

  .section-right .home1-laptop {
    max-width: 95%;

    @media (max-width: 991.98px) {
      max-width: 65%;
    }
  }

  .section-right img {
    max-width: 75%;

    @media (max-width: 991.98px) {
      max-width: 65%;
    }

    // height: 520px;
  }

  // .section-right img {
  //   width: 600px;
  //   @media (max-width: 1400px) {
  //     width: 500px;
  //   }
  //   @media (max-width: 991.98px) {
  //     width: 400px;
  //   }
  //   // height: 520px;
  // }

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    &.rtl {
      direction: unset;
    }
  }
}