.pricing,
.pricing2 {
  .pre-text {
    margin: 20px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 767.98px) {
      margin: 20px;
    }

    @media (max-width: 575.98px) {
      margin: 20px;
    }

    .header-group {
      display: flex;
      flex-direction: row;

      .title {
        font-weight: 600;
        font-size: 30px;
        line-height: 53px;
        padding-left: 20px;

        @media (max-width: 767.98px) {
          font-size: 26px;
        }

        @media (max-width: 575.98px) {
          font-size: 25px;
        }
      }
    }

    .sub-title {
      padding: 0 40px;
      border-left: 4px solid #9dcdcf;
      font-weight: 500;
      font-size: 30px;
      line-height: 45px;
      margin-top: 40px;
    }
  }

  .table-wrapper {
    overflow-x: auto;
    max-width: 100%;
  }

  .pricing-table {
    margin: 20px auto;
    width: calc(100% - 160px);
    table-layout: fixed;
    border-collapse: collapse;
    overflow-wrap: break-word;
    min-width: 920px;
    max-width: 1200px;

    @media (max-width: 575.98px) {
      margin: 30px;
      width: 80%;
    }

    th {
      padding: 16px;

      @media (max-width: 575.98px) {
        padding: 12px;
      }

      .feature-style {
        padding: 32px 4px;
        height: auto;
        border-radius: 15px;
        background-color: #e6f5ea;
        width: 324px;
        margin: auto;

        @media (max-width: 991.98px) {
          width: 312px;
          padding: 26px 4px;
        }

        .billing-type {
          border: 1px solid #DADADA;
          border-radius: 4px;
          color: #9A9A9A;
          padding: 8px 10px;
          cursor: pointer;

          &.active {
            background: #3EAE5B;
            color: #FFF;
          }

          @media (max-width: 1230px) {
            font-size: 14px;
          }

        }

        .old-price {
          text-decoration: line-through;
          text-decoration-color: red;
        }

        h2 {
          font-size: 36px;

          &.free-plan {
            padding-top: 26px;
          }

          @media (max-width: 991.98px) {
            font-size: 25px;

            &.free-plan {
              padding-top: 22px;
            }
          }
        }

        h3 {
          color: #474f64;
          font-size: 30px;

          &.free-price {
            padding-top: 25px;
          }

          @media (max-width: 991.98px) {
            font-size: 22px;

            &.free-price {
              padding-top: 21px;
            }
          }
        }

        h4 {
          font-size: 20px;
          color: #596173;
        }

        button {
          padding: 19px 33px;
          color: white;
          background-color: #3eae5b;
          border: none;
          border-radius: 12px;
          font-size: 18px;
          cursor: pointer;

          @media (max-width: 767.98px) {
            padding: 16px;

            font-size: 15px;
          }
        }

      }

      img {
        width: 100%;
      }
    }

    tr {
      border-bottom: 1px solid #a3a7b1;

      td {
        text-align: center;
        padding: 40px 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #474f64;
      }

      td:first-child,
      th:first-child {
        text-align: left;
      }

      th:first-child {
        vertical-align: bottom;
        padding-left: 0;
      }
    }

    tfoot tr {
      border-bottom: none;
    }
  }
}