.data-list {
  direction: ltr;
  display: flex;
  flex-direction: column;
  div {
    margin: 12px 0;
    display: flex;
    flex-direction: row;
    svg {
      vertical-align: top;
    }
    span {
      font-size: 28px;
      line-height: 42px;
      flex: 1 1 0;
      padding-left: 24px;
    }

  }
}