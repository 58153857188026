.section-with-list {
  &.section {
    background-color: #E6F5EA;
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-evenly;
    padding: 40px;
    align-items: flex-start;
    margin: 40px 0;
    @media (max-width: 767.98px) {
      width: 100%;
      padding: 20px;
    }
    @media (max-width: 575.98px) {
      width: 100%;
      padding: 0;
    }
   
  }
  
  .list-item {
    width: 320px;
    @media (max-width: 767.98px) {
      width: 100%;
      
    }
    @media (max-width: 575.98px) {
      width: 100%;
      text-align: center;
    }
  }
  
  .list-title {
    margin: 12px 0;
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    color: #238E43;
    @media (max-width: 767.98px) {
      font-size: 18px;
    }
    @media (max-width: 575.98px) {
      font-size: 16px;
      line-height: 30px;
      margin: 6px 0;
    }
  }
  
  .list-description {
    margin: 12px 0;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    min-height: 140px;
    color: #353D54;
    @media (max-width: 767.98px) {
      font-size: 14px;
    }
    @media (max-width: 575.98px) {
      font-size: 15px;
      margin: 6px 0;
      line-height: 30px;
    }
  }
 

}