.modal-header {
  padding: 2.5rem;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  display: flex;

  button {
    font-size: .875rem;
    line-height: 1.25rem;
    border: none;
    background-color: #0000;
    cursor: pointer;
  }
}

.modal-body {
  margin: 24px;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
}

.modal-footer {
  padding: 28px;
  text-align: center;
}