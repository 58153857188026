.overlay {
  height: 100% !important;
  width: 100% !important;
  display: block;
  position: fixed !important;
  z-index: 9999999999 !important;
  top: 0 !important;
  left: 0 !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  overflow-x: hidden !important;
  transition: 0.5s !important;
}

div#wave {
  position: relative;
  margin-top: 50vh;
  text-align: center;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  .dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;
    background: #fff;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}
