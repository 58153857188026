
    .contact-info {
        margin: 40px 0;
        padding: 40px 72px;
        font-size: 24px;
        line-height: 32px;

        h2 {
            margin: 0;
        }

        .contact-email {
            margin: 24px 0 12px;
        }
    }
