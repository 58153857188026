.footer {
  background-color: #e6f5ea;
  color: #474f64;
  line-height: 1.5;
  ul {
    list-style: none;
    padding-left: 0;
  }
  a {
    text-decoration: none;
    color: #474f64;
  }
  a:hover {
    text-decoration: underline;
  }
  .ft-title {
    color: #474f64;
    font-family: "Times New Roman", Times, serif;
    font-size: 28px;
    padding-bottom: 10px;
    font-weight: 900;
    @media (max-width: 575.98px) {
      font-size: 16px;
    }
  }
  .ft-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media (max-width: 575.98px) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
    }
  }
  .ft-main-item {
    padding: 20px 20px 0;
  }
  .ft-social-list {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin: 4px;
    width: 92%;
    @media (max-width: 575.98px) {
      width: 100%;
    }
  }
  .ft-social-list li {
    margin: 8px;
  }
  .ft-legal {
    border-top: 1px #a3a7b1 solid;
    padding: 4px 12px;
  }
  .ft-legal-list {
    width: 92%;
    display: flex;
  }
  .ft-legal-list li {
    white-space: nowrap;
    margin-left: auto;
  }
  .ft-legal-list li:nth-last-child(1) {
    margin-left: auto;
  }

  // @media only screen and (min-width: 1240px) {
  //   .ft-main {
  //     justify-content: space-evenly;
  //   }
  // }
  @media (max-width: 575.98px) {
    // .ft-social-list {
    //   flex-direction: column;
    // }
  }
}
