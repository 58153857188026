.section-text {
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  margin: 80px 0;
  @media (max-width: 575.98px) {
    font-size: 25px;
    margin: 80px 8px;
  }
}